import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>Fluid Mechanics</h1>
    <h2>{`Fluid mechanics books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PZjlxYlZPTWhnM28"
      }}>{`Download: A textbook of fluid mechanics and hydraulic machines by Dr. R.K Bansal`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PQkhrUmIyUWFTTk0"
      }}>{`Download: Fluid mechanics with engineering applications by Robert L. Daugherty `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PQmQ4TmJkZ2xkanM"
      }}>{`Download: Fluid mechanics by Frank M. White 4th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PV0Rsb3FUWWZrazg"
      }}>{`Download: Fluid mechanics by John F. Doughlas, Janusz M. Gasiorek, John A. Swaffield, Lynne B. Jack`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PMVptREU3Rm1FbTQ"
      }}>{`Download: Fluid mechanics Fundamentals and applications by Yunus A. Cengel`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PWUVocUZNd215VGc"
      }}>{`Download: Fluid mechanics by Frank M. White 7th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1U7xv2tRa-hJJ7Ym1XweQLv-2ioLau599"
      }}>{`Download: Fluid mechanics with engineering applications by E. John Finnemore, Joseph B. Franzini `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-POEt4YklIdGltVTg"
      }}>{`Download: Fluid mechanics by Dr. Colin Caprani`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=1-hAOUik9X674KFvcvWUzd-67px3UXntQ"
      }}>{`Download: Practical fluid mechanics for engineering applications by John J. Bloomer`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PTlhnSlAtaU4tYmM"
      }}>{`Download: Solving problems in fluid mechanics by J.F. Douglas volume 1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PVmJaYUVCMnVOS2c"
      }}>{`Download: Solving problems in fluid mechanics by J.F. Douglas volume 2`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=11HN4X095rIki0UaW7Dx2hDYbnFDdOkj5"
      }}>{`Download: Fluid mechanics and thermodynamics of turbo machinery by S.L. Dixon `}</a></p>
    <h2>{`Fluid mechanics II Lab manual solved`}</h2>
    <h2>{`Fluid mechanics II Lectures and Solved problems`}</h2>
    <h3>{`Solution to problems:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/impact_of_jets.pdf"
      }}>{`Impact_of_jets`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/pelton_wheel.pdf"
      }}>{`Pelton_wheel`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/reaction_turbines.pdf"
      }}>{`Reaction_turbines`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/centeifugal_pumps.pdf"
      }}>{`Centrifugal_pumps`}</a></p>
    <h3>{`Lecture slides:`}</h3>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Ch-6-Differential-Analysis-of-Fluid-Flow-part-I.ppt"
      }}>{`Ch 6 Differential Analysis of Fluid Flow part I`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Ch-6-Differential-Analysis-of-Fluid-Flow-part-II.ppt"
      }}>{`Ch 6 Differential Analysis of Fluid Flow part II`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Ch-6-Differential-Analysis-of-Fluid-Flow-part-III-viscous-flow.ppt"
      }}>{`Ch 6 Differential Analysis of Fluid Flow part III viscous flow`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Ch-9-Flow-Over-Immersed-Bodies.ppt"
      }}>{`Ch 9 Flow Over Immersed Bodies`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Ch-11-Compressible-Flow.ppt"
      }}>{`Ch 11 Compressible Flow`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Ch-12-Turbomachines.ppt"
      }}>{`Ch 12 Turbomachines`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Design-of-pelton-turbines.pdf"
      }}>{`Design of pelton turbines`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Centrifugal_pump.pdf"
      }}>{`Centrifugal_pumps`}</a></p>
    <h2>{`Fluid mechanics I selected topics`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Center-of-Pressure-and-Hydrostatic-Force-on-a-submerged-body-Rev.pdf"
      }}>{`Center of Pressure and Hydrostatic Force on a submerged body-Rev`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/fluid-mechanics-basic-concepts.pdf"
      }}>{`fluid mechanics basic concepts`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Hydrostatic-Force-on-a-Plane-Surface.pdf"
      }}>{`Hydrostatic Force on a Plane Surface`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Hydrostatic-Force-on-submerged-body.pdf"
      }}>{`Hydrostatic Force on submerged body`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Hydrostatics-forces.pdf"
      }}>{`Hydrostatics forces`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Laboratory-manual-of-fluid-mechanics.pdf"
      }}>{`Laboratory manual of fluid mechanics`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Metacentric-height-of-floating-bodies.pdf"
      }}>{`Metacentric height of floating bodies`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/stability-of-floating-body.pdf"
      }}>{`stability of floating body`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/To-determine-the-Metacentric-height-of-a-floating-body.pdf"
      }}>{`To determine the Metacentric height of a floating body`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Transitional-Flow-Between-Orifice-and-Non-Orifice-Regimes-at-a-Re.pdf"
      }}>{`Transitional Flow Between Orifice and Non-Orifice Regimes`}</a></p>
    <h2>{`Solution manual of problems (Fluid mechanics with engineering applications by Robert L. Daugherty)`}</h2>
    <p>{`Download solution manual of exercise problems in Fluid mechanics with engineering applications by Robert L.  Daugherty`}</p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/solution-manual-ch-1-12-fluid-mechanics-with-engineering-applications-by-robert-l-daugherty/"
      }}>{`Chapter 1 and 12 Solution to exercise selective problems`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/solution-manual-ch-2-fluid-mechanics-with-engineering-applications-daugherty/"
      }}>{`Chapter 2 Solution to exercise selective problems `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/solution-manual-ch-3-fluid-mechanics-with-engineering-applications-daugherty/"
      }}>{`Chapter 3 Solution to exercise selective problems`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/solution-manual-ch-4-fluid-mechanics-with-engineering-applications-daugherty/"
      }}>{`Chapter 4 Solution to exercise selective problems `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/solution-manual-ch6-fluid-mechanics-with-engineering-applications-daugherty/"
      }}>{`Chapter 6 Solution to exercise selective problems `}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/FM-assignment.pdf"
      }}>{`FM assignment`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      